@use '../../../variables';

.payment-container {
    height: 100%;
}

.payment-container-loading {
    background-color: #ffffff;
    position: absolute;
    height: 100%;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.payment-container-loaded {
    height: 100%;
    overflow: auto;
    padding: 25px 15px;
}

.payment-content {
    font-size: 14px;
    padding: 30px 15px;
    background-color: #ffffff;
    max-width: 350px;
    min-width: 275px;
    border-radius: 10px;
    margin: 0 auto;

    &.lg {
        max-width: 855px;
    }
}

.recurring-payments-legal-mention {
    font-size: 12px;
    font-weight: 500;
    margin-top: 10px;
}

@media screen and (max-width: variables.$sm) {
    .payment-container-loaded {
        padding: 15px;
    }
}

@media screen and (max-width: variables.$xs) {
    .payment-container-loaded {
        padding: 5px;
    }

    .payment-content {
        padding: 20px 10px;
    }
}
