.expiration-countdown {
    text-align: center;
    margin: 10px 0 35px;

    &.expired{
        margin: 10px 0 0;
    }

    &.highlight{
        color: #ab0000;
    }
}
