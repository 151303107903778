.error-container {
    color: #c11930;
    font-weight: 500;
    display: grid;
    grid-template-rows: auto auto;
    padding: 50px 15px;
    justify-items: center;
    row-gap: 10px;
    max-width: 500px;
    margin: 0 auto;
    text-align: center;

    p {
        margin: 0;
    }
}
