.payment-method-switcher-label {
    margin-bottom: 10px;
}

.payment-method-switcher-selector {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 10px;
    margin-bottom: 30px;
}

$paymentMethodSelectorBg: #e0e0e0;

.payment-method-switcher-selector-item {
    text-align: center;
    background-color: $paymentMethodSelectorBg;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color .3s;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        max-width: 45px;
        max-height: 20px;
    }

    &:hover {
        background-color: darken($color: $paymentMethodSelectorBg, $amount: 5);
    }

    &.selected {
        background-color: darken($color: $paymentMethodSelectorBg, $amount: 10);
    }

    &.disabled {
        opacity: .5;
        cursor: not-allowed;
    }
}
