@use '../../../variables';

.welcome-page-container {
    padding: 40px 20px;
    text-align: center;
    background-color: #253F66;
    color: #FFFFFF;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    img {
        max-width: 300px;
        width: 100%;
        margin-bottom: 15px;
    }
}

@media screen and (max-width: variables.$sm) {
    .welcome-page-container img {
        max-width: 230px;
    }
}
